import React from 'react';
import { raiseEvent } from '@x-functions/website-analytics-sdk';
import { useToasts } from 'react-toast-notifications';
import useCart from '../../hooks/cart';

function Product({ product }) {
  const { addItem } = useCart();
  const { addToast } = useToasts();

  const handleAddToCartClick = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    addItem(product);
    addToast('Product added to cart', {
      appearance: 'info',
    });
  }, [addItem, product, addToast]);

  const handleLikeClick = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    raiseEvent('add-to-attribute', { attributeName: 'liked_products', value: product.name });
    addToast('Liked ❤️', {
      appearance: 'info',
    });
  }, [product, addToast]);

  return (
    <div className="card">
      <div className="card-image" style={{ display: 'flex', justifyContent: 'center' }}>
        <figure className="image" style={{ height: '300px', width: '100px' }}>
          <img src={product.image} alt="Guitar" style={{ maxHeight: '300px' }}/>
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4" style={{ color: '#000' }}>{product.name} - {product.color}</p>
            <p className="subtitle is-5" style={{ color: '#000' }}>{product.strings} Strings</p>
            <p className="subtitle is-4" style={{ color: '#000', textDecoration: 'line-through' }}>Retail price: US ${product.price.toFixed(2)}</p>
            <p className="subtitle is-4" style={{ color: '#000' }}>Member price: US ${(product.price * 0.8).toFixed(2)}</p>
          </div>
        </div>
      </div>
      <footer className="card-footer">
        <a href="#" onClick={handleAddToCartClick} className="card-footer-item">Add to cart</a>
        <a href="#" onClick={handleLikeClick} className="card-footer-item">Like</a>
      </footer>
    </div>
  );
}

export default Product;
