import { init } from '@x-functions/website-analytics-sdk';
import React from 'react';
import {
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  VerifyContact,
  withAuthenticator,
} from 'aws-amplify-react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import PageTracker from './components/pageTracker';
import SignIn from './components/signin';
import SignUp from './components/signup';
import ConfirmSignUp from './components/confirmSignup';
import Navbar from './components/navbar';
import Home from './pages/home';
import Cart from './pages/cart';
import Confirmed from './pages/order-confirmed';
import createStore from './createStore';
import { updateUserDetails } from './reducers/user';
import './aws';
import './App.sass';

const store = createStore();

function App({ authState }) {
  React.useEffect(() => {
    init(
      process.env.REACT_APP_ANALYTICS_ORG_ID || 'demo',
      process.env.REACT_APP_ANALYTICS_APP_ID || '708a441b9f904912af17de179c8a1b46',
      {
        isSPA: true,
        disablePageTracker: true,
        apiDomain: process.env.REACT_APP_ANALYTICS_API_DOMAIN,
      },
    );
  }, []);

  React.useEffect(() => {
    // eslint-disable-next-line
    if (authState === 'signedIn') {
      store.dispatch(updateUserDetails());
    }
  }, [authState]);

  if (authState !== 'signedIn') {
    return null;
  }

  return (
    <Router>
      <PageTracker />
      <ToastProvider autoDismiss autoDismissTimeout={4000} placement='bottom-left'>
        <Provider store={store}>
          <div className="App">
            <Navbar />
            <Switch>
              <Route path="/cart">
                <Cart />
              </Route>
              <Route path="/order-confirmed">
                <Confirmed />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Provider>
      </ToastProvider>
    </Router>
  );
}

export default withAuthenticator(App, false, [
  <SignIn />,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword />
]);
