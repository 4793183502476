import { useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { addToCart, updateCartItem, removeFromCart, emptyCart as emptyCartAction, selectCartDetails, selectCartData } from '../reducers/cart';

function useCart() {
  const dispatch = useDispatch();
  const addItem = useCallback(product => dispatch(addToCart({
    product,
    qty: 1,
  })), [dispatch]);
  const updateItem = useCallback(item => dispatch(updateCartItem(item)), [dispatch]);
  const removeItem = useCallback(item => dispatch(removeFromCart(item)), [dispatch]);
  const emptyCart = useCallback(() => dispatch(emptyCartAction()), [dispatch]);
  const cart = useSelector(selectCartDetails, shallowEqual);
  const cartData = useSelector(selectCartData, shallowEqual);

  return {
    cart,
    cartData,
    addItem,
    updateItem,
    removeItem,
    emptyCart,
  };
}

export default useCart;
