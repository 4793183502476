export const guitars = [{
  name: 'A1.6BOP',
  strings: 6,
  price: 1099,
  color: 'Black open pore',
  image: 'https://www.solar-guitars.com/wp-content/uploads/2020/09/A1.6BOP-FRONT-VERTICAL-v2-346x1024.png',
}, {
  name: 'A1.7LTD',
  strings: 7,
  price: 1299,
  color: 'Aged natural',
  image: 'https://www.solar-guitars.com/wp-content/uploads/2019/10/A1.7D-LTD-FRONT-VERTICAL-347x1024.png',
}, {
  name: 'A1.8C',
  strings: 8,
  price: 1299,
  color: 'Carbon black matte',
  image: 'https://www.solar-guitars.com/wp-content/uploads/2019/03/A1.8C-FRONT-VERTICAL-330x1024.png',
}];
