import React from 'react';
import { Link } from 'react-router-dom';

function Confirmed() {
  return (
    <React.Fragment>
      <section className="hero is-primary">
        <div className="hero-body">
          <h1 className="title">Order Sent</h1>
          <p>Your order is being processed - you'll receive a confirmation via Email and SMS soon.</p>
          <p>Thank you for shopping with X-Guitars!</p>
          <p><Link to="/">Back to store</Link></p>
        </div>
      </section>
      <div className="notification is-danger is-light" style={{ marginBottom: '0px' }}>
        This is a demo website that uses <a href="https://makerops.com">X-Functions</a> to instrument and automate experiences.&nbsp;
        <b>Using this website will not result in an actual purchase.</b>
      </div>
    </React.Fragment>
  );
}

export default Confirmed;
