import { createAction, createReducer } from 'redux-act';

export const addToCart = createAction('/cart/add');
export const updateCartItem = createAction('/cart/update');
export const removeFromCart = createAction('/cart/remove');
export const emptyCart = createAction('/cart/empty');

export default createReducer({
  [emptyCart]: () => ({}),
  [addToCart]: (state, item) => {
    const id = item.product.name;
    const oldItem = state[id] || {};
    return {
      ...state,
      [id]: {
        ...oldItem,
        product: item.product,
        qty: item.qty + (oldItem.qty || 0),
      }
    };
  },
  [updateCartItem]: (state, item) => ({
    ...state,
    [item.product.name]: item,
  }),
  [removeFromCart]: (state, item) => {
    const { [item.product.name]: itm, ...rest } = state;
    return rest;
  },
}, {});

export const selectCartDetails = state => {
  const { cart } = state;
  const items = Object.values(cart);
  const totalValue = items.reduce((acc, curr) => {
    return {
      qty: acc.qty + curr.qty,
      total: acc.total + (curr.product.price * curr.qty),
    }
  }, {
    qty: 0,
    total: 0,
  });

  return {
    items,
    ...totalValue,
  };
};

export const selectCartData = state => state.cart;
