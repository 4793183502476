import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { recordPurchase } from '@x-functions/website-analytics-sdk';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import useCart from '../../hooks/cart';
import useUser from '../../hooks/user';
import './styles.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};


function Cart({ history }) {
  const { cart, cartData, updateItem, removeItem, emptyCart } = useCart();
  const { userId } = useUser();
  const [error, setError] = React.useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleMinusClick = item => () => {
    if (item.qty <= 1) return removeItem(item);

    return updateItem({
      ...item,
      qty: item.qty - 1,
    });
  };

  const handlePlusClick = item => () => {
    return updateItem({
      ...item,
      qty: item.qty + 1,
    });
  };

  const handleCardChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result && result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      await axios.post(process.env.REACT_APP_CHECKOUT_API_URL, {
        cardToken: result.token,
        cart: cartData,
        userId,
      });

      cart.items.forEach(item => {
        recordPurchase(item.product.name, 'USD', item.product.price * 0.8, item.qty);
      });
      emptyCart();
      history.push("/order-confirmed");
    }
  };

  return (
    <React.Fragment>
      <section className="hero is-primary">
        <div className="hero-body">
          <h1 className="title">Cart</h1>
          <h2 className="subtitle">Items in the cart: {cart.qty}</h2>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Unit Price</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
                {cart.items.map(item => (
                  <tr key={item.product.name}>
                    <td>{item.product.name} - {item.product.color}</td>
                    <td>${(item.product.price).toFixed(2)}</td>
                    <td>
                      <span className="buttons">
                        <span className="button is-text">{item.qty}</span>
                        <button className="button" onClick={handlePlusClick(item)}>+</button>
                        <button className="button" onClick={handleMinusClick(item)}>-</button>
                      </span>
                    </td>
                    <td>${(item.product.price * item.qty).toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td>
                    <span className="buttons">
                      <span className="button is-text">{cart.qty}</span>
                    </span>
                  </td>
                  <td>${cart.total.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Member discount</td>
                  <td></td>
                  <td></td>
                  <td><b>-20%</b></td>
                </tr>
                <tr>
                  <td><b>Total</b></td>
                  <td></td>
                  <td></td>
                  <td>${(cart.total * 0.8).toFixed(2)}</td>
                </tr>
            </tbody>
          </table>
        </div>
      </section>
      <div className="notification is-danger is-light" style={{ marginBottom: '0px' }}>
        This is a demo website that uses <a href="https://makerops.com">X-Functions</a> to instrument and automate experiences.&nbsp;
        <b>Using this website will not result in an actual purchase.</b>
      </div>
      <section className="hero">
        <div className="hero-head pl-5 pt-5">
          <h2 className="title">Payment</h2>
        </div>
        <div className="hero-body">
          <form className="box" onSubmit={handleSubmit}>
            <label className="label" for="card-element">
              Credit or debit card
            </label>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flexGrow: 1 }}>
                <CardElement
                  id="card-element"
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={handleCardChange}
                />
              </div>
              <button type="submit" className="button is-primary">Submit Payment</button>
            </div>
            <div className="card-errors" role="alert">{error}</div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CartWithStripe = (props) => (
  <Elements stripe={stripePromise}>
    <Cart {...props} />
  </Elements>
);

export default withRouter(CartWithStripe);
