import React from 'react';
import { guitars } from '../../content/guitars';
import Product from '../../components/product';

function Home() {
  return (
    <React.Fragment>
      <section className="hero is-primary">
        <div className="hero-body">
          <h1 className="title">Guitar of the month</h1>
          <h2 className="subtitle">Solar Guitar Type A</h2>
          <div className="columns">
            {guitars.map(guitar => (
              <div key={guitar.name} className="column">
                <Product product={guitar} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="notification is-danger is-light" style={{ marginBottom: '0px' }}>
        This is a demo website that uses <a href="https://makerops.com">X-Functions</a> to instrument and automate experiences.&nbsp;
        <b>Using this website will not result in an actual purchase.</b>
      </div>
    </React.Fragment>
  );
}

export default Home;
