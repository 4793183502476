/* eslint-disable import/no-extraneous-dependencies */
import Auth from '@aws-amplify/auth';

Auth.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    mandatorySignIn: false,
  },
});

export {
  Auth,
};
