import { shallowEqual, useSelector } from 'react-redux';
import { selectUserId, selectUserEmail } from '../reducers/user';

function useCart() {
  const userId = useSelector(selectUserId, shallowEqual);
  const userEmail = useSelector(selectUserEmail, shallowEqual);

  return {
    userId,
    userEmail,
  };
}

export default useCart;
